import React, { useState, useEffect } from 'react'
import AWS from 'aws-sdk'
import { AWS_CONFIG } from '../utils/config'

// AWS configuration
AWS.config.update({
  accessKeyId: AWS_CONFIG.accessKeyId,
  secretAccessKey: AWS_CONFIG.secretAccessKey,
  region: AWS_CONFIG.region,
})

const lambda = new AWS.Lambda()

function Chat() {
  const [messages, setMessages] = useState([
    {
      sender: 'bot',
      text: 'Hello! How can I help you today?',
    },
  ])
  const [userMessage, setUserMessage] = useState('')
  const [hasSentInitialInstruction, setHasSentInitialInstruction] = useState(false)
  const [isBotTyping, setIsBotTyping] = useState(false) // New state for bot typing

  const initialInstruction = ''

  // Function to check if the message is a compliment
const checkForCompliment = (message) => {
  const positiveWords = ['thank', 'thanks', 'appreciate', 'awesome', 'great', 'marvellous'];
  const wordCount = message.trim().split(/\s+/).length; // Count the number of words

  if (wordCount < 6) {
    const messageLower = message.toLowerCase();
    return positiveWords.some(word => messageLower.includes(word)); // Check for positive words
  }

  return false; // Not a compliment
};

const boldNumberedLists = (text) => {
  // Regular expression to match numbered lists
  const regex = /(\d+\. )/g;

  // Replace matches with bolded text
  return text.replace(regex, (match) => `<strong>${match}</strong>`);
};

  const handleSendMessage = async () => {
    if (!userMessage.trim()) return

    let modifiedUserMessage = userMessage

    // Check for compliments before proceeding
    const isCompliment = checkForCompliment(userMessage);
    if (isCompliment) {
      setMessages([...messages, { sender: 'user', text: userMessage },{ sender: 'bot', text: 'Thanks for your kind words!' }]);
      setUserMessage('');
      return; // Stop further processing if a compliment is detected
    }

    if (!hasSentInitialInstruction) {
      modifiedUserMessage = `${initialInstruction} ${userMessage}`
      setHasSentInitialInstruction(true)
    }

    const newMessages = [...messages, { sender: 'user', text: userMessage }]
    console.log('newMess', newMessages)
    setMessages(newMessages)
    setUserMessage('')

    // Set bot typing to true 
    setIsBotTyping(true)

    try {
      const conversationHistory = newMessages
        .map((msg) => `${msg.sender}: ${msg.text}`)
        .join('\n')

      const params = {
        FunctionName: AWS_CONFIG.lambdaFunctionName,
        InvocationType: 'RequestResponse',
        Payload: JSON.stringify({
          message: conversationHistory.replace(
            userMessage,
            modifiedUserMessage,
          ),
        }),
      }

      const result = await lambda.invoke(params).promise()
      const responsePayload = JSON.parse(result.Payload)
      const chatbotResponse = JSON.parse(responsePayload.body).response

      setMessages([...newMessages, { sender: 'bot', text: chatbotResponse }])
    } catch (error) {
      console.error('Error invoking Lambda function:', error)
      setMessages([
        ...newMessages,
        {
          sender: 'bot',
          text: 'Sorry, something went wrong. Please try again.',
        },
      ])
    } finally {
      // Set bot typing to false after the response
      setIsBotTyping(false)
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage()
    }
  }

  const formatMessageText = (txt) => {
    if (!txt) {
      return txt
    }
    // const text = boldNumberedLists(txt)
    return txt.split('\n').map((str, index) => (
      <span key={index}>
        {str}
        <br />
      </span>
    ))
  }

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault()
      e.returnValue =
        'Are you sure you want to leave? Your chat history will be lost.'
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  return (
    <div>
      <div className="header">
        <h1>Welcome to the Sunshine Coast</h1>
      </div>
      <div className="container">
        <img
          src="https://www.betterremovalistssunshinecoast.com.au/wp-content/uploads/2019/09/sc-city.webp"
          alt="Static Image"
          className="background-image"
        />

        <div className="chatbot-flyout open">
          <div className="chatbot-content">
            <div className="chat-container">
              <div className="chat-header">
                <h3>Hyperplanner AI</h3>
              </div>
              <div className="chat-body">
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className={`message ${
                      message.sender === 'bot' ? 'bot-message' : 'user-message'
                    }`}
                  >
                    {message.sender === 'bot' && message.link ? (
                      <p>
                        {formatMessageText(message.text)}{' '}
                        <a
                          href={message.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          (Click here for more info)
                        </a>
                      </p>
                    ) : (
                      <p>{formatMessageText(message.text)}</p>
                    )}
                  </div>
                ))}

                {/* Bot typing animation */}
                {(isBotTyping) && (
                    <div className="message bot-message">
                        <span style={{marginTop:5}}>Hyperplanner is thinking <i className='fa fa-spin fa-spinner'></i></span>
                    </div>
                )}
              </div>
              <div className="chat-footer">
                <input
                  type="text"
                  value={userMessage}
                  onChange={(e) => setUserMessage(e.target.value)}
                  onKeyDown={handleKeyDown}
                  placeholder="Type a message..."
                />
                <button onClick={handleSendMessage}>
                  <i className="fa fa-paper-plane"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Chat
