import '@aws-amplify/ui-react/styles.css';
import Chat from "./views/Chat"
import { withAuthenticator } from '@aws-amplify/ui-react';

function App() {
 const isLoggedIn =1
 if(isLoggedIn){
    return (
      <Chat/>
    )
  }else{
      return (<p>You are not logged in</p>)
  }

}
export default withAuthenticator(App);
// export default App
